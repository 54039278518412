<template>
  <div class="activity-description-container">
    <div
      class="good-introduce"
      v-html="description"
    >
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'

export default {
  data () {
    return {
      description: '',
    }
  },
  mounted () {
    this.getInviteList()
  },
  methods: {
    // 获取邀请信息
    getInviteList () {
      api
        .get('event/getUserEventInfo')
        .then(result => {
          if (result.data.success) {
            this.description = result.data.data.description
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.activity-description-container {
  margin-top: 3.875rem;
  .good-introduce {
    min-height: calc(100vh - 6.875rem);
    background: #262626;
    border-radius: 8px;
  }
}
</style>